<template>
    <div style="padding: 2%">
        <div class="page-header">
            <h3>Campaign <b>Management</b></h3>
        </div>
        <div v-if="!editor && !notesView" id="hrs" class="row">
            <div class="col-xs-12 mainside">
                <div class="well well-lg">
                    <div class="row text-left">
                        <div class="col-xs-12 col-md-4 text-center">
                            <h1><i class="fa fa-3 fa-user" aria-hidden="true"></i></h1>
                            <h4>Status: <span class="badge badge-secondary" style="text-transform: uppercase;">{{item.status}}</span> </h4>
                            <div class="row">

                            </div>
                            <p class="hidden">Please remember that you'll only be able to view the campaign information on this page <b> </b>. If you want to edit this campaign please click the update campaign button below. </p>
                            <div>
<!--                                <button @click="editor = true" class="btn btn-primary btn-block" ><i class="fa fa-user"></i> Update Campaign</button>-->
                                <hr/>
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body text-center" >
                                            <b-spinner type="grow" label="Loading..." variant="success" v-if="hitsToday === false">
                                                Loading.......
                                            </b-spinner>
                                            <div v-if="hitsToday === true">
                                                <h4>{{Number(hitsTodayTotal).toLocaleString()}}</h4>
                                                <h5>Successful Clicks Today</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="row stats mb-12">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <b-spinner type="grow" label="Loading..." variant="success" v-if="hitsToday === false"></b-spinner>
                                            <div v-if="hitsToday === true">
                                                <h4>{{Number(report.stats.hits.today.total).toLocaleString()}} hits</h4>
                                                <h5>Today</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <b-spinner type="grow" label="Loading..." variant="danger" v-if="missesToday === false"></b-spinner>
                                            <div v-if="missesToday === true">
                                                <h4>{{Number(report.stats.misses.today.total).toLocaleString()}} failed</h4>
                                                <h5>Today</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row stats mb-12" v-if="selectedStat === 'Yesterday'">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-body text-center" @click="viewChart('hits','yesterday','hitsYesterday')">
                                            <b-spinner type="grow" label="Loading..." variant="success" v-if="hitsYesterday === false"></b-spinner>
                                            <div v-if="hitsYesterday === true">
                                                <h4>{{Number(report.stats.hits.yesterday.total).toLocaleString()}} hits</h4>
                                                <h5>Yesterday</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-body text-center" @click="viewChart('misses','yesterday','missesYesterday')">
                                            <b-spinner type="grow" label="Loading..." variant="danger" v-if="missesYesterday === false"></b-spinner>
                                            <div v-if="missesYesterday === true">
                                                <h4>{{Number(report.stats.misses.yesterday.total).toLocaleString()}} failed</h4>
                                                <h5>Yesterday</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                        </div>

                        <div class="col-xs-12 col-md-4">
                            <h4>Account Information</h4>
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" v-model="item.client.Name" class="form-control" value="" readonly/>
                            </div>
                            <div class="form-group">
                                <label>Email Address</label>
                                <input type="text" v-model="item.client.Email" class="form-control" value="" readonly/>
                            </div>
                            <div class="form-group">
                                <label>Phone</label>
                                <input type="text" v-model="item.client.PhoneNumber" class="form-control" readonly/>
                            </div>
                            <div class="form-group">
                                <label>Type</label>
                                <input type="text" v-model="item.type" class="form-control" readonly/>
                            </div>



                        </div>

                        <div class="col-xs-12 col-md-4">
                            <h4>Campaign Information</h4>
                            <div class="form-group">
                                <label>Start Date</label>
                                <input type="date" v-model="item.startDate" class="form-control" readonly/>
                            </div>

                            <div class="form-group">
                                <label>Crawl Threshold</label>
                                <input type="number" v-model="item.threshold" class="form-control" readonly/>
                                <div class="form-text text-muted">Maximum number of crawl nodes that should run at once. Recommended 25.</div>
                            </div>
                            <div class="form-group">
                                <label>Target Clicks</label>
                                <input type="text" v-model="item.target" class="form-control" readonly/>
                                <div class="form-text text-muted">Maximum clicks on this campaign.</div>
                            </div>
                            <div class="form-group">
                                <label>Max Daily Clicks</label>
                                <input type="text" v-model="item.maxDailyClicks" class="form-control" readonly/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid mt-4">
                    <h3>Logs</h3>
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" href="#change" data-toggle="tab">Change History</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#logs" data-toggle="tab">Campaign Logs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#notes" data-toggle="tab">Notes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#threshold" data-toggle="tab">Threshold Logs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#pitcher" data-toggle="tab">Pitcher Logs</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="change">
                            <div class="table-responsive plans mt-5">
                                <h4>Change history</h4>
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <div class="form-inline">
                                            <div class="form-group mr-1">
                                                <input type="date" v-model="filter1.from" class="form-control" placeholder="From Date"/>
                                            </div>
                                            <div class="form-group mr-1">
                                                <input type="date" v-model="filter1.to" class="form-control" placeholder="To Date"/>
                                            </div>
                                            <button type="button" class="btn btn-secondary mr-1" @click="loadChange"><i class="fa fa-search" title="Search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Queued Jobs</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="!loadData">
                                    <tr v-for="item in Changelogs.data" v-bind:key="item._id">
                                        <td>{{(item.from) ? item.from : 'N/A'}}</td>

                                        <td>{{(item.to) ? item.to : 'N/A'}}</td>

                                        <td>{{(item.value) ? item.value : 'N/A'}}</td>

                                        <td>{{item.date|userTime(user.timezone)}}</td>
                                    </tr>
                                    </tbody>
                                    <tbody v-if="loadData">
                                    <tr>
                                        <td class="text-center" colspan="4">
                                            <img  src="/25.gif"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-12 col-sm-9">
                                        <v-pagination
                                            v-model="page"
                                            :pageCount="Math.ceil(Changelogs.total/max)"
                                            :classes="bootstrapPaginationClasses"
                                            :labels="customLabels"
                                            @input="loadChange"></v-pagination>
                                    </div>
                                    <div class="col-12 col-sm-3 text-right">
                                        <span v-if="Changelogs.data">{{Changelogs.data.length * Number(page|1)}}</span> of <span>{{Changelogs.total}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="logs">
                            <div class="table-responsive transactions mt-5">
                                <h4>Campaign logs </h4>
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <div class="form-inline">
                                            <div class="form-group mr-1">
                                                <input type="date" v-model="filter.from" class="form-control" placeholder="From Date"/>
                                            </div>
                                            <div class="form-group mr-1">
                                                <input type="date" v-model="filter.to" class="form-control" placeholder="To Date"/>
                                            </div>
                                            <button type="button" class="btn btn-secondary mr-1" @click="loadLogs"><i class="fa fa-search" title="Search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Link</th>
                                        <th>Provider</th>
                                        <th>IP Address</th>
                                        <th>Message</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="!loadDataLogs">
                                    <tr v-for="item in logs.data" v-bind:key="item._id">
                                        <td>{{item.date|userTime(user.timezone)}}</td>
                                        <td><span v-if="item.data">{{item.data.link}}</span></td>
                                        <td><span v-if="item.proxy">{{item.proxy.provider}}</span></td>
                                        <td><span v-if="item.proxy">{{item.proxy.ip}}</span></td>
                                        <td>{{item.message}}</td>
                                        <td>{{item.type||'N/A'}}</td>
                                        <td>{{item._id|makeID}}</td>
                                    </tr>
                                    </tbody>
                                    <tbody v-if="loadDataLogs">
                                    <tr>
                                        <td class="text-center" colspan="6">
                                            <img  src="/25.gif"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-12 col-sm-9">
                                        <v-pagination
                                            v-model="page"
                                            :pageCount="Math.ceil(logs.total/max)"
                                            :classes="bootstrapPaginationClasses"
                                            :labels="customLabels"
                                            @input="loadLogs">
                                        </v-pagination>
                                    </div>
                                    <div class="col-12 col-sm-3 text-right">
                                        <span v-if="logs.data">{{logs.data.length * Number(page|1)}}</span> of <span>{{logs.total}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="notes">
                            <div class="table-responsive notes mt-5">
                                <h4>Notes History <button @click="notesView = true" class="btn btn-success">Add Note</button></h4>
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th><i class="fa fa-comments" aria-hidden="true"></i></th>
                                        <th>Date</th>
                                        <th>Note</th>
                                        <th>Author</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr v-for="note in notes.data"  v-bind:key="note._id">
                                        <td>
                                            <i class="fa fa-comment" aria-hidden="true"></i>
                                        </td>
                                        <td>{{note.createdAt|date_format}}</td>
                                        <td>{{note.description}}</td>
                                        <td>{{(note.user && note.user.name)? note.user.name : 'N/A'}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="threshold">
                            <div class="table-responsive threshold mt-5">
                                <h4>Threshold Logs</h4>
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <div class="form-inline">
                                            <div class="form-group mr-1">
                                                <input type="date" v-model="tfilter1.from" class="form-control" placeholder="From Date"/>
                                            </div>
                                            <div class="form-group mr-1">
                                                <input type="date" v-model="tfilter1.to" class="form-control" placeholder="To Date"/>
                                            </div>
                                            <button type="button" class="btn btn-secondary mr-1" @click="loadChange"><i class="fa fa-search" title="Search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Type</th>
                                            <th>Hour</th>
                                            <th>Admin</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!loadData">
                                        <tr v-for="item in Thresholdlogs.data" v-bind:key="item._id">
                                            <td>{{ item.created_ts|userTime(user.timezone)}}</td>
                                            <td>{{(item.from) ? item.from : 'N/A'}}</td>
                                            <td>{{(item.to) ? item.to : 'N/A'}}</td>
                                            <td>{{(item.type) ? item.type : 'global'}}</td>
                                            <td>{{(item.hour) ? toHour(item.hour) : 'N/A'}}</td>
                                            <td>{{(item.user) ? item.user.name : 'N/A'}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="loadData">
                                    <tr>
                                        <td class="text-center" colspan="4">
                                            <img  src="/25.gif"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-12 col-sm-9">
                                        <v-pagination
                                            v-model="tpage"
                                            :pageCount="Math.ceil(Thresholdlogs.total/max)"
                                            :classes="bootstrapPaginationClasses"
                                            :labels="customLabels"
                                            @input="loadThreshold"></v-pagination>
                                    </div>
                                    <div class="col-12 col-sm-3 text-right">
                                        <span v-if="Thresholdlogs.data">{{Thresholdlogs.data.length * Number(page|1)}}</span> of <span>{{Thresholdlogs.total}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="pitcher">
                            <div class="table-responsive threshold mt-5">
                                <h4>Pitcher Logs</h4>
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <div class="form-inline">
                                            <div class="form-group mr-1">
                                                <input type="date" v-model="pfilter.from" class="form-control" placeholder="From Date"/>
                                            </div>
                                            <div class="form-group mr-1">
                                                <input type="date" v-model="pfilter.to" class="form-control" placeholder="To Date"/>
                                            </div>
                                            <div class="form-group mr-1">
                                                <select v-model="pfilter.type" class="form-control text-capitalize">
                                                    <option value="success">-- Success --</option>
                                                    <option value="fail">-- Fail --</option>
                                                </select>
                                            </div>
                                            <button type="button" class="btn btn-secondary mr-1" @click="loadPitcherLogs"><i class="fa fa-search" title="Search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div style="max-height: 500px; overflow-y: scroll; overflow-x: hidden">
                                    <table class="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th style="width: 100px">Date</th>
                                            <th style="width: 100px">Time</th>
                                            <th>Message</th>
                                            <th>Type</th>
                                        </tr>
                                        </thead>
                                        <tbody v-if="!pitcherLoadData">
                                        <tr v-for="item in PitcherLogs" v-bind:key="item._id">
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.time }}</td>
                                            <td>{{ item.message }}</td>
                                            <td>{{ item.type }}</td>
                                        </tr>
                                        </tbody>
                                        <tbody v-if="pitcherLoadData">
                                        <tr>
                                            <td class="text-center" colspan="4">
                                                <img  src="/25.gif"/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="editor" class="row">
            <div class="col-12 col-sm-12">
                <form class="form" role="form" style="" @submit="save">
                    <div class="row">
                        <div class="col-12 col-sm-8">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" v-model="item.name" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>Domain</label>
                                <input type="text" v-model="item.domain" class="form-control required" required/>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Type</label>
                                <select v-model="item.type" class="form-control text-capitalize required" required>
                                    <option value="">-- Type --</option>
                                    <option value="domain">Domain</option>
                                    <!--<option value="loadtest">Load Testing</option>-->
                                    <!--<option value="workflows">Workflows</option>-->
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Status</label>
                                <select v-model="item.status" class="form-control required" required>
                                    <option value="">-- Status --</option>
                                    <option value="active">Active</option>
                                    <option value="disabled">Disabled</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Client</label>
                                <input type="text" v-model="item.client" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>Email Address</label>
                                <input type="email" v-model="item.email" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>Start Date</label>
                                <input type="date" v-model="item.startdate" class="form-control"/>
                            </div>

                            <div class="form-group">
                                <label>End Date</label>
                                <input type="date" v-model="item.enddate" class="form-control"/>
                            </div>

                            <div class="form-group">
                                <label>Start Time</label>
                                <input type="time" v-model="item.startTime" class="form-control"/>
                            </div>

                            <div class="form-group">
                                <label>End Time</label>
                                <input type="time" v-model="item.endTime" class="form-control"/>
                            </div>

                            <div class="form-group">
                                <label>Time Zone</label>
                                <select v-model="item.timezone" class="form-control text-capitalize">
                                    <option value="" disabled>Time Zone</option>
                                    <option v-for="(timezone, index) in timezones" :value="timezone" :key="index">{{ timezone }}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Crawl Threshold</label>
                                <input type="number" v-model="item.threshold" class="form-control required" min="1" value="10" required/>
                                <div class="form-text text-muted">Maximum number of crawl nodes that should run at once. Recommended 25.</div>
                            </div>

                            <div class="form-group">
                                <label>Crawl Throttling</label>
                                <select v-model="item.throttle" class="form-control text-capitalize">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <div class="form-text text-muted">Adjusts the crawl threshold based on each node's resources.</div>
                            </div>

                            <div class="form-group">
                                <label>Time on Site</label>
                                <input type="number" v-model="item.timeonsite" class="form-control required" min="0" value="1" required/>
                                <div class="form-text text-muted">Maximum time on the site in minutes.</div>
                            </div>

                            <div class="form-group">
                                <label>Time on Site Variation</label>
                                <select v-model="item.timeonsite_variation" class="form-control text-capitalize">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <div class="form-text text-muted">Adjusts the tos to add enthropy.</div>
                            </div>

                            <div class="form-group">
                                <label>Target Clicks</label>
                                <input type="number" v-model="item.target" class="form-control" min="0" value="0"/>
                                <div class="form-text text-muted">Maximum clicks on this campaign.</div>
                            </div>

                            <div class="form-group" v-if="item.type != 'loadtest'">
                                <label>Max Daily Clicks</label>
                                <input type="number" v-model="item.clicksdaily" class="form-control" min="0" max="999999999" value="0"/>
                            </div>

                            <!--<div class="form-group">
                                <label>Max Retries</label>
                                <input type="number" v-model="item.retries" class="form-control" min="0" value="0"/>
                                <div class="form-text text-muted">Maximum number of times to retry</div>
                            </div>-->
                        </div>

                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Local Links Only</label>
                                <select v-model="item.localLinks" class="form-control text-capitalize">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <div class="form-text text-muted">Only allow links from the campaign domain.</div>
                            </div>

                            <div class="form-group">
                                <label>Link Level</label>
                                <input type="number" v-model="item.linkLevel" class="form-control" min="0" value="0"/>
                                <div class="form-text text-muted">Maximum depth of links to follow this campaign.</div>
                            </div>

                            <div class="form-group">
                                <label>Local Links Navigation Alternative</label>
                                <select v-model="item.alternative" class="form-control text-capitalize">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                                <div class="form-text text-muted">Use page.goto instead of anchor click to navigate.</div>
                            </div>

                            <div class="form-group">
                                <label>Bounce</label>
                                <select v-model="item.bounce" class="form-control text-capitalize">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                                <div class="form-text text-muted">Allows the campaign to bounce a modulus of the traffic to the target.</div>
                            </div>

                            <div class="form-group">
                                <label>Behaviors</label>
                                <input type="text" v-model="item.behaviors" class="form-control" value="2500,7000,5000,8500,15000"/>
                                <div class="form-text text-muted">The list of wait times that the system can shuffle through in miliseconds. 1 sec = 1000ms.</div>
                            </div>

                            <div class="form-group">
                                <label>Navigation Timeout</label>
                                <input type="number" v-model="item.timeout" class="form-control" min="0" value="0"/>
                                <div class="form-text text-muted">Maximum amount of time to wait on page navigation.</div>
                            </div>

                            <div class="form-group">
                                <label>Wait Until</label>
                                <select v-model="item.waitUntil" class="form-control text-capitalize">
                                    <option value="load">Load event is fired</option>
                                    <option value="domcontentloaded">DOMContentLoaded event is fired</option>
                                    <option value="networkidle0">0 Network connections</option>
                                    <option value="networkidle2">&lt;&equals; 2 Network connections</option>
                                </select>
                                <div class="form-text text-muted">When to consider navigation succeeded.</div>
                            </div>

                            <div class="form-group">
                                <label>Mobile</label>
                                <select v-model="item.mobile" class="form-control text-capitalize">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                                <div class="form-text text-muted">Allows the campaign to bounce a modulus of the traffic to the target.</div>
                            </div>

                            <!--<div class="form-group">
                                <label>Backoff Factor</label>
                                <input type="number" v-model="item.backoff" class="form-control" step="0.01" min="0" value="0"/>
                                <div class="form-text text-muted">Backoff factoring value</div>
                            </div>-->
                        </div>

                        <div class="col-12 col-sm-4" v-if="item.type == 'workflows'">
                            <workflow></workflow>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-8">
                            <div class="form-group">
                                <label>Referers</label>
                                <textarea rows="5" v-model="item.referers" class="form-control" placeholder="Referers"></textarea>
                                <div class="form-text text-muted">The comma separated list of paths to avoid.</div>
                            </div>

                            <div class="form-group" v-if="item.type != 'loadtest'">
                                <label>Blacklist</label>
                                <textarea rows="5" v-model="item.blacklist" class="form-control" placeholder="Blacklisted paths"></textarea>
                                <div class="form-text text-muted">The comma separated list of paths to avoid.</div>
                            </div>

                            <div class="form-group" v-if="item.type != 'loadtest'">
                                <label>Use Proxy</label>
                                <select v-model="item.proxy" class="form-control text-capitalize">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </div>

                            <div v-if="item.proxy == 'yes'">
                                <!--<div class="form-group">
                                    <label>Clicks Per IP</label>
                                    <input type="number" v-model="item.proxy_clicksperip" class="form-control" min="0" max="999999999" value="0"/>
                                </div>
                                <div class="form-group">
                                    <label>Proxy Retries</label>
                                    <input type="number" v-model="item.proxy_retries" class="form-control" min="0" max="999999999" value="0"/>
                                </div>-->
                                <div class="form-group">
                                    <label>Proxy Countries</label>
                                    <textarea rows="5" v-model="item.proxy_countries" class="form-control" placeholder="Allowed Proxy Countries"></textarea>
                                    <div class="form-text text-muted">The comma separated list of countries to use proxies from. Specific providers and be specified by prefixing the entry with the name of the provider ex: luminate:US/FL/Miami.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group text-right">
                                <button type="button" class="btn btn-secondary btn-sm" @click="cancel"><span class="glyphicon glyphicon-remove"></span> Cancel</button>
                                <button type="submit" class="btn btn-success" :disabled="!item.domain || (item.type == 'workflow' && (!item.workflows || !item.workflows.length))"><span class="glyphicon glyphicon-ok"></span> Save Changes</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="notesView" class="row">
            <div class="col-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <form v-on:submit.prevent="saveNote">
                            <div class="row">
                                <div class="col-12 col-md-7">
                                    <h4>Notes Information</h4>
                                    <p>Update Notes information</p>

                                    <div class="form-group">
                                        <label>Description</label>
                                        <textarea v-model="note.description" rows="4" cols="8" class="form-control"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <div class="form-group">
                                        <input type="button" @click="notesView = false" class="btn btn-danger btn-lg mr-3" value="Cancel"/>
                                        <input type="submit" class="btn btn-success btn-lg" value="Submit"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import swal from "sweetalert";
    import axios from 'axios';
    import vPagination from 'vue-plain-pagination';
    import moment from "moment-timezone";
    export default {
        name: "Campaignview",
        props: ['user'],
        components: {
            vPagination
        },
        data() {
            return {
                timezones: moment.tz.names(),
                editor: false,
                notesView: false,
                filter: {from:'', to:'', campaign:''},
                filter1: {from:'', to:'', campaign:''},
                tfilter: {from:'', to:'', campaign:''},
                tfilter1: {from:'', to:'', campaign:''},
                pfilter: {from:'', to:'', campaign:'', type:'fail' },
                filters:{},
                filters1:{},
                notes:[],
                note:{},
                hitsToday: false,
                hitsYesterday: false,
                hitsMonth: false,
                hitsTotal: false,
                missesToday: false,
                missesYesterday: false,
                missesMonth: false,
                missesTotal: false,
                hitsTodayTotal: 0,
                loadData:false,
                loadDataLogs:false,
                pitcherLoadData:false,
                selectedStat: "Today",
                originalReport: {
                    name:'',
                    stats: {
                        hits:{
                            today:{
                                total:0,
                                report:{}
                            },
                            yesterday:{
                                total:0,
                                report:{}
                            },
                            month:{
                                total:0,
                                report:{}
                            },
                            total:{
                                total:0,
                                report:{}
                            }
                        },
                        misses:{
                            today:{
                                total:0,
                                report:{}
                            },
                            yesterday:{
                                total:0,
                                report:{}
                            },
                            month:{
                                total:0,
                                report:{}
                            },
                            total:{
                                total:0,
                                report:{}
                            }
                        }
                    },
                    chartdata:{

                    }
                },
                logs:[],
                report:[],
                Changelogs:[],
                Thresholdlogs:{},
                PitcherLogs:[],
                plans:[],
                states:[],
                countries:[],
                item: {
                    timezone: ''
                },
                page:1,
                tpage:1,
                max:25,
                totalPages:1,
                bootstrapPaginationClasses: {
                    ul: 'pagination',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                customLabels: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            }
        },
        created() {
            this.filter.to = this.getDateFormatted(new Date());
            this.filter.from = this.getDateFormatted(new Date());
            this.filter1.to = this.getDateFormatted(new Date());
            this.filter1.from = this.getDateFormatted(new Date());
            this.tfilter.to = this.getDateFormatted(new Date());
            this.tfilter.from = this.getDateFormatted(new Date());
            this.tfilter1.to = this.getDateFormatted(new Date());
            this.tfilter1.from = this.getDateFormatted(new Date());
            this.pfilter.to = this.getDateFormatted(new Date());
            this.pfilter.from = this.getDateFormatted(new Date());
            this.load();
            this.hitsTodayMethod(this.$route.query.campaign);
            this.$root.preloader = false;
            //this.reports2();
            //this.loadHits();
            /*if(this.$route.query.member){
                this.load();
                this.loadNotes();
                this.loadPlans();
                this.loadCountries();
                this.loadStates();
            }*/
        },
        methods: {
            toHour(h) {
                let suffix = (h >= 12)? 'pm' : 'am';
                let hours = (h > 12) ? h - 12 : h;
                hours = (hours == 0) ? 12 : hours;
                return `${hours} ${suffix}`
            },
            cancel: function(){
                this.editor = false
                this.errormsg = false
            },
            hitsTodayMethod: function(obj){
                //Show reports on the item
                this.hitsToday = false;

                var request = {'apikey': this.$root.apikey}
                axios.get(`${this.$root.serverUrl}/admin/campaigns/stats/${obj}?task=today`, {params:request}).then(function(resp){
                    this.hitsToday = true;
                    this.hitsTodayTotal = resp.data.data.today.total;
                }.bind(this)).catch((err)=>{
                    console.log('report.error', err)
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                });
            },

            loadNotes() {

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.filter1.campaign) request.filters.campaign_id = this.filter1.campaign;

                for (var f in this.filters1) {
                    if (this.filters1[f]) {
                        if (f == 'search')
                            request.search = this.filters1[f];
                        else
                            request.filters[f] = this.filters1[f];
                    }
                }

                return axios.get(`${this.$root.serverUrl}/admin/notes`, {params: request}).then(function (result) {
                    //debugger;
                    this.notes = (result && result.data) ? result.data : [];
                    //this.totalPages = Math.round((Number(result.data.total) / Number(this.max)));
                }.bind(this))
            },


            loadCountries(){
                var page
                if (event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf('page-link') > -1) {
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = Number(this.page);
                } else
                    page = 1

                var request = {search: '', page: page, max: 5000};

                return this.$axios.$get(`/admin/api/country`,{params: request}).then(function(result){
                    this.countries = result.data || [];
                }.bind(this))
            },

            loadStates(){
                var page
                if (event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf('page-link') > -1) {
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = Number(this.page);
                } else
                    page = 1

                var request = {search: '', page: page, max: 5000};
                return this.$axios.$get(`/admin/api/state`,{params: request}).then(function(result){
                    this.states = result.data || [];
                }.bind(this))
            },

            saveNote: function(){
                //Handle saving the changes
                this.note.user_id = this.$root.apikey;
                this.note.campaign_id = this.item._id;
                this.note.created_ts = new Date(Date.UTC(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
                var request = {'apikey': this.$root.apikey, id:this.note._id, args:this.note};
                this.$root.preloader = true

                axios({url:`${this.$root.serverUrl}/admin/notes`, data:request, responseType:'json', method:(this.note['_id'])? 'POST' : 'PUT'}).then(function(resp){
                    //Process the results
                    this.$root.preloader = false
                    if(resp.data && !resp.data.error){
                        //Saved successfully, now update the parent
                        swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(function(val){
                            if(val) {
                                this.notesView = false;
                                this.loadNotes()
                            }
                        }.bind(this))
                    } else {
                        //Handle errors
                        this.errormsg = (resp.data && resp.data.error)? resp.data.error : false
                        this.$root.preloader = false
                    }
                }.bind(this)).catch(function(err){
                    this.$root.preloader = false
                    this.errormsg = err.message||"An unexpected error occured"
                    swal({title:"Error", text: this.errormsg, icon:"error"})
                }.bind(this))
            },

            save: function(){
                //Handle saving the changes
                var request = {'apikey': this.$root.apikey, id:this.item._id, args:this.item};
                this.$root.preloader = true

                axios({url:`${this.$root.serverUrl}/admin/campaigns`, data:request, responseType:'json', method:(this.item['_id'])? 'POST' : 'PUT'}).then(function(resp){
                    //Process the results
                    this.$root.preloader = false
                    if(resp.data && !resp.data.error){
                        //Saved successfully, now update the parent
                        swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(function(val){
                            if(val) {
                                this.editor = false
                                this.search()
                            }
                        }.bind(this))
                    } else {
                        //Handle errors
                        this.errormsg = (resp.data && resp.data.error)? resp.data.error : false
                        this.$root.preloader = false
                    }
                }.bind(this)).catch(function(err){
                    this.$root.preloader = false
                    this.errormsg = err.message||"An unexpected error occured"
                    swal({title:"Error", text: this.errormsg, icon:"error"})
                }.bind(this))
            },

            loadLogs: function(){
                //Load the items
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};


                if(this.filter.campaign) request.campaign = this.filter.campaign;
                if(this.filter.from || this.filter.to){
                    request.filters.date = {};
                    if(this.filter.from) request.filters.date.$gte = this.filter.from;
                    if(this.filter.to) request.filters.date.$lte = this.filter.to;
                }
                //request.type = 'campaign';
                request.page = page;
                request.max = this.max;
                this.loadDataLogs = true;

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/logdetails`, {params:request}).then(function(resp){
                    //Store the stats
                    this.logs = (resp.data)? resp.data : {total:0, data:[]};
                    this.loadDataLogs = false;
                }.bind(this));
            },

            loadPitcherLogs: function(){
                //Load the items
                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.pfilter.campaign) request.campaign = this.pfilter.campaign;
                if(this.pfilter.type) request.type = this.pfilter.type;
                if(this.pfilter.from || this.pfilter.to){
                    request.filters.date = {};
                    if(this.pfilter.from) request.filters.date.$gte = this.pfilter.from;
                    if(this.pfilter.to) request.filters.date.$lte = this.pfilter.to;
                }
                this.pitcherLoadData = true;

                axios.get(`${this.$root.serverUrl}/admin/campaigns/pitcherdetails`, {params:request}).then(function(resp){
                    this.PitcherLogs = (resp.data)? resp.data : [];
                    this.pitcherLoadData = false;
                }.bind(this));
            },

            reports: function(){
                //Show reports on the item
                var request = {'apikey': this.$root.apikey}

                axios.get(`${this.$root.serverUrl}/admin/campaigns/report/${this.filter.campaign}`, {params:request}).then(function(resp){
                    //Store the stats
                    this.report = resp.data
                    this.report.chartdata = {data:[{name: this.report.name, "x-name": "Hour", "y-name": "Hits", data: []}]}
                    for(var i in this.report.hours){
                        this.report.chartdata.data[0].data.push({label: `${i} Hour`, value:this.report.hours[i]})
                    }
                    this.$root.preloader = false
                }.bind(this)).catch(function(err){
                    console.log('report.error', err)
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                })
            },

            loadChange: function(){
                //Load the items
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.filter1.campaign) request.filters.campaign_id = this.filter1.campaign;
                if(this.filter1.from || this.filter1.to){
                    request.filters.date = {};
                    if(this.filter1.from) request.filters.date.$gte = this.filter1.from.replace(/-/g,'/');
                    if(this.filter1.to) request.filters.date.$lte = this.filter1.to.replace(/-/g,'/');
                }

                request.page = page;
                request.max = this.max;
                this.loadData = true;
                return axios.get(`${this.$root.serverUrl}/admin/campaigns/changes`, {params:request}).then(function(resp){
                    //Store the stats
                    this.Changelogs = (resp.data)? resp.data : {total:0, data:[]};
                    this.loadData = false;

                }.bind(this));
            },

            loadThreshold: function(){
                //Load the items
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.tpage)? ((this.tpage == 1)? 0 : Number(this.tpage-1) ) : 0
                } else
                    page = 0

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.tfilter1.campaign) request.filters.campaign_id = this.tfilter1.campaign;
                if(this.tfilter1.from || this.tfilter1.to){
                    request.filters.date = {};
                    if(this.filter1.from) request.filters.date.$gte = this.tfilter1.from.replace(/-/g,'/');
                    if(this.filter1.to) request.filters.date.$lte = this.tfilter1.to.replace(/-/g,'/');
                }

                request.page = page;
                request.max = this.max;
                this.loadData = true;
                return axios.get(`${this.$root.serverUrl}/admin/campaigns/threshold/changes`, {params:request}).then(function(resp){
                    //Store the stats
                    this.Thresholdlogs = (resp.data)? resp.data : {total:0, data:[]};
                    this.loadData = false;

                }.bind(this));
            },

            getDateFormatted: function(d){
                var
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                return [year, month, day].join('-');
            },
            load() {
                this.filters = {};
                this.filters._id = this.$route.query.member;
                var page
                if (event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf('page-link') > -1) {
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = Number(this.page);
                } else
                    page = 1

                var request = {'apikey': this.$root.apikey, search: '', page: page, max: this.max, filters: {}};

                for (var f in this.filters) {
                    if (this.filters[f]) {
                        if (f == 'search')
                            request.search = this.filters[f];
                        else
                            request.filters[f] = this.filters[f];
                    }
                }

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/${this.$route.query.campaign}/_id`, {params: request}).then(function (result) {
                    this.item = (result && result.data) ? result.data.data : {};

                    this.filter.campaign = result.data.data._id;
                    this.filter1.campaign = result.data.data._id;
                    this.filters1.campaign_id = result.data.data._id;
                    this.tfilter1.campaign = result.data.data._id;
                    this.pfilter.campaign = result.data.data._id;

                    this.loadChange();
                    this.loadLogs();
                    this.loadNotes();
                    this.loadThreshold();
                    this.loadPitcherLogs();

                    this.totalPages = Math.round((Number(result.total) / Number(this.max)));
                }.bind(this));
            }
        }

    }
</script>

<style scoped>

</style>
